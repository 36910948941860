import {ONE_HUNDRED_THOUSAND, ShippingMethodType} from '../../components/cart/constants';
import {ICart} from '../../types/app.types';

export const getCatalogAppIds = (cart: ICart): string => {
  return getMappedUniqueList(cart?.items, ({catalogAppId}) => catalogAppId);
};

export const getItemTypes = (cart: ICart): string => {
  return getMappedUniqueList(cart.items, ({product: {productType}}) => productType);
};

function getMappedUniqueList<T>(arr: T[] | undefined, mapper: (t: T) => string | undefined | null): string {
  return getUniques((arr ?? /* istanbul ignore next */ []).map(mapper).filter(isDefined)).toString();
}

const getUniques = (arr: string[]): string[] => {
  return [...new Set([...arr])];
};

const isDefined = <T>(obj: T): boolean => {
  return !!obj;
};

export const getAdditionalFeesPrice = (cart: ICart): number => {
  return (cart?.totals?.additionalFeesTotal ?? 0) * ONE_HUNDRED_THOUSAND;
};

export const getNumberOfAdditionalFees = (cart: ICart): number => {
  return cart?.additionalFees?.length ?? 0;
};

export const getShippingMethodType = (isPickupFlow: boolean, isDigitalCart: boolean): ShippingMethodType => {
  const existingShippingMethodType = isPickupFlow ? ShippingMethodType.PICKUP : ShippingMethodType.SHIPPING;
  return isDigitalCart ? ShippingMethodType.NONE : existingShippingMethodType;
};

export const getOriginalShippingMethod = (cart: ICart): string => {
  const options = cart.shippingRuleInfo?.shippingRule?.options || [];
  const selectedShippingRuleOption = options.find((option) => option.id === cart.selectedShippingOption.id);
  return selectedShippingRuleOption?.title ?? '';
};
